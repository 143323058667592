<template>
  <modal name="metricModal" :height="'80%'" :width="'50%'" :adaptive="true">
    <v-card class="pa-4">
      <div class="row">
        <help :helpId="122" style="'float:left; margin-right:7px;'" />

        <v-form ref="formCompany"  style="width: 100%" class="row">
          <v-col cols="12">
            <v-text-field counter label="Descripción" />
          </v-col>
          <v-col cols="12">
            <v-select
              :items="['Texto', 'Numerico', 'Booleano']"
              label="Periocidad*"
              required
            />
          </v-col>
          <v-col cols="12">
            <v-text-field counter label="Icono" />
          </v-col>
        </v-form>
      </div>
    </v-card>
    <v-col cols="12" style="margin-bottom: 20px; height: 10%">
      <div class="row">
        <v-btn small
          style="margin: auto; margin-top: 10px"
          dark
          color="primary"
          @click="createItem()"
        >
          Crear
        </v-btn>
        <v-btn small
          style="margin: auto; margin-top: 10px"
          color="primary"
          dark
          @click="$modal.hide('metricModal')"
        >
          Cancelar
        </v-btn>
      </div>
    </v-col>
  </modal>
</template>
<script>
  import Help from '@/components/App/Help'
  export default {
    components: {
      Help,
    },
  }
</script>
