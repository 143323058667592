var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"4","md":"4"}},[(typeof _vm.metrics.metric[0] !== 'object')?_c('v-hover',{attrs:{"disabled":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto",attrs:{"elevation":hover ? 12 : 2,"height":"100","max-width":""}},[_c('v-card-text',{staticClass:"my-4 text-center text-h6",style:('background-image:('+require( '@/assets/chart.png' )+');')},[_c('v-spacer'),_c('v-btn',{staticClass:"ma-2",attrs:{"outlined":"","large":"","fab":"","color":"primary"},on:{"click":function($event){return _vm.$modal.show('metricModal')}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)],1)]}}],null,false,1679933268)}):_c('single-metric',{attrs:{"title":_vm.metrics.metric[0].description,"metric":_vm.metrics.metric[0].indicator,"icon":_vm.metrics.metric[0].icon,"loading":_vm.loading,"helpId":1,"customStyles":'position: absolute; right: 5px; bottom: 5px'}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"4","md":"4"}},[(typeof _vm.metrics.metric[1] !== 'object')?_c('v-hover',{attrs:{"disabled":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto",attrs:{"elevation":hover ? 12 : 2,"height":"100","max-width":""}},[_c('v-card-text',{staticClass:"my-4 text-center text-h6",style:('background-image:('+require( '@/assets/chart.png' )+');')},[_c('v-spacer'),_c('v-btn',{staticClass:"ma-2",attrs:{"outlined":"","large":"","fab":"","color":"primary"},on:{"click":function($event){return _vm.$modal.show('metricModal')}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)],1)]}}],null,false,1679933268)}):_c('single-metric',{attrs:{"title":_vm.metrics.metric[1].description,"metric":_vm.metrics.metric[1].indicator,"icon":_vm.metrics.metric[1].icon,"loading":_vm.loading,"helpId":2,"customStyles":'position: absolute; right: 5px; bottom: 5px'}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"4","md":"4"}},[(typeof _vm.metrics.metric[2] !== 'object')?_c('v-hover',{attrs:{"disabled":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto",attrs:{"elevation":hover ? 12 : 2,"height":"100","max-width":""}},[_c('v-card-text',{staticClass:"my-4 text-center text-h6",style:('background-image:('+require( '@/assets/chart.png' )+');')},[_c('v-spacer'),_c('v-btn',{staticClass:"ma-2",attrs:{"outlined":"","large":"","fab":"","color":"primary"},on:{"click":function($event){return _vm.$modal.show('metricModal')}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)],1)]}}],null,false,1679933268)}):_c('single-metric',{attrs:{"title":_vm.metrics.metric[2].description,"metric":_vm.metrics.metric[2].indicator,"icon":_vm.metrics.metric[2].icon,"loading":_vm.loading,"helpId":2,"customStyles":'position: absolute; right: 5px; bottom: 5px'}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"6","md":"6"}},[(typeof _vm.metrics.metric[3] !== 'object')?_c('v-hover',{attrs:{"disabled":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto",attrs:{"elevation":hover ? 12 : 2,"height":"350","max-width":""}},[_c('v-img',{staticStyle:{"margin-top":"10px"},attrs:{"src":require( '@/assets/chart.png' ),"height":"","max-width":"350"}},[_c('v-card-text',{staticClass:"my-4 text-center text-h6"},[_c('v-spacer'),_c('v-btn',{staticClass:"ma-2",attrs:{"outlined":"","large":"","fab":"","color":"primary"},on:{"click":function($event){return _vm.$modal.show('metricModal')}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)],1)],1)]}}],null,false,3291223273)}):_c('v-card',{staticClass:"justify-center single-metric",attrs:{"loading":_vm.loading}},[_c('v-toolbar',{staticStyle:{"border":"1px solid #000","height":"35px"},attrs:{"flat":"","color":"grey lighten-4"}},[_c('v-toolbar-title',[_c('h5',[_vm._v(_vm._s(_vm.metrics.metric[3].description))])]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)],1),_c('v-card-text',{staticClass:"py-0"},[(_vm.metrics.metric[3].type == 2)?_c('line-chart',{style:({ height: '402px' }),attrs:{"data":_vm.data1.data,"options":_vm.data1.options}}):(_vm.metrics.metric[3].type == 3)?_c('bar-chart',{style:({ height: '402px' }),attrs:{"data":_vm.data1.data,"options":_vm.data1.options}}):_vm._e()],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"6","md":"6"}},[(typeof _vm.metrics.metric[4] !== 'object')?_c('v-hover',{style:('background-image:('+require( '@/assets/chart.png' )+');'),attrs:{"disabled":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto text-center",attrs:{"elevation":hover ? 12 : 2,"height":"350"}},[_c('v-img',{staticStyle:{"margin-top":"10px"},attrs:{"src":require( '@/assets/chart.png' ),"height":"","max-width":"350"}},[_c('v-card-text',{staticClass:"my-4 text-center text-h6"},[_c('v-spacer'),_c('v-btn',{staticClass:"ma-2",attrs:{"outlined":"","large":"","fab":"","color":"primary"},on:{"click":function($event){return _vm.$modal.show('metricModal')}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)],1)],1)]}}],null,false,377637819)}):_c('v-card',{staticClass:"justify-center single-metric",attrs:{"loading":_vm.loading}},[_c('v-toolbar',{staticStyle:{"border":"1px solid #000","height":"35px"},attrs:{"flat":"","color":"grey lighten-4"}},[_c('v-toolbar-title',[_c('h5',[_vm._v(_vm._s(_vm.metrics.metric[4].description))])]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)],1),_c('v-card-text',{staticClass:"py-0"},[(_vm.metrics.metric[4].type == 2)?_c('line-chart',{style:({ height: '402px' }),attrs:{"data":_vm.data2.data,"options":_vm.data2.options}}):(_vm.metrics.metric[4].type == 3)?_c('bar-chart',{style:({ height: '402px' }),attrs:{"data":_vm.data2.data,"options":_vm.data2.options}}):(_vm.metrics.metric[4].type == 4)?_c('v-progress-circular',{staticClass:"text-center",staticStyle:{"margin":"50px"},attrs:{"value":_vm.metrics.metric[4].indicator,"size":"300","width":"25","color":"primary"}},[_c('div',{staticClass:"text-center"},[_c('div',{staticClass:"text-h4 ml-3"},[_vm._v(_vm._s(_vm.metrics.metric[4].indicator)),_c('sup',[_vm._v("%")])])])]):_vm._e()],1)],1)],1),_c('modal')],1)}
var staticRenderFns = []

export { render, staticRenderFns }