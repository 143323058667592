<template>
    <v-row align="center">
        <v-col cols="12" sm="6" lg="4" md="4">
          <v-hover v-if="typeof metrics.metric[0] !== 'object'"
            v-slot="{ hover }"
            disabled
          >
            <v-card
            :elevation="hover ? 12 : 2"
            class="mx-auto"
            height="350"
            max-width="350"
            >
            <v-img
                :src="require( '@/assets/chart.png' )"
                height=""
                style="margin-top:10px;"
              >
                <v-card-text class="my-4 text-center text-h6">
                    <v-spacer/>
                    <v-btn
                        class="ma-2"
                        outlined
                        large
                        fab
                        color="primary"
                        @click="$modal.show('metricModal')"
                        >
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </v-card-text>
            </v-img>
           </v-card>
          </v-hover>
        </v-col>
        <v-col cols="12" sm="6" lg="4" md="4">
          <v-hover v-if="typeof metrics.metric[1] !== 'object'"
            v-slot="{ hover }"
            disabled
          >
            <v-card
            :elevation="hover ? 12 : 2"
            class="mx-auto"
            height="350"
            max-width="350"
            >
            <v-img
                :src="require( '@/assets/chart.png' )"
                height=""
                style="margin-top:10px;"
              >
                <v-card-text class="my-4 text-center text-h6">
                    <v-spacer/>
                    <v-btn
                        class="ma-2"
                        outlined
                        large
                        fab
                        color="primary"
                        @click="$modal.show('metricModal')"
                        >
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </v-card-text>
            </v-img>
           </v-card>
          </v-hover>
        </v-col>
        <v-col cols="12" sm="6" lg="4" md="4">
          <v-hover v-if="typeof metrics.metric[2] !== 'object'"
            v-slot="{ hover }"
            disabled
          >
            <v-card
            :elevation="hover ? 12 : 2"
            class="mx-auto"
            height="350"
            max-width="350"
            >
            <v-img
                :src="require( '@/assets/chart.png' )"
                height=""
                style="margin-top:10px;"
              >
                <v-card-text class="my-4 text-center text-h6">
                    <v-spacer/>
                    <v-btn
                        class="ma-2"
                        outlined
                        large
                        fab
                        color="primary"
                        @click="$modal.show('metricModal')"
                        >
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </v-card-text>
            </v-img>
           </v-card>
          </v-hover>
          <modal />
        </v-col>
      </v-row>
</template>
<script>
import Modal from './Modal'
export default {
    components: {
      Modal
    },
    props: {
        metrics: {
            type: Object,
            default: null,
        },
    },
    data: () => ({
      stats: {},
      loading: false,
      menuItems: [
        {
          id: 1,
          name: 'Cursos y Lecciones',
          icon: 'mdi-clipboard-edit-outline',
          link: '/business/elearning/courses',
        }
      ],
    }),
  }
</script>
