<template>
    <v-row align="center">
        <v-col cols="12" sm="6" lg="4" md="4">
          <v-hover v-if="typeof metrics.metric[0] !== 'object'"
            v-slot="{ hover }"
            disabled
          >
            <v-card
            :elevation="hover ? 12 : 2"
            class="mx-auto"
            height="100"
            max-width=""
            >
                <v-card-text class="my-4 text-center text-h6" :style="'background-image:('+require( '@/assets/chart.png' )+');'">
                    <v-spacer/>
                    <v-btn
                        class="ma-2"
                        outlined
                        large
                        fab
                        color="primary"
                        @click="$modal.show('metricModal')"
                        >
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </v-card-text>
           </v-card>
          </v-hover>
          <single-metric
            v-else
            :title="metrics.metric[0].description"
            :metric="metrics.metric[0].indicator"
            :icon="metrics.metric[0].icon"
            :loading="loading"
            :helpId="1"
            :customStyles="'position: absolute; right: 5px; bottom: 5px'"
          />
        </v-col>
        <v-col cols="12" sm="6" lg="4" md="4">
          <v-hover v-if="typeof metrics.metric[1] !== 'object'"
            v-slot="{ hover }"
            disabled
          >
            <v-card
            :elevation="hover ? 12 : 2"
            class="mx-auto"
            height="100"
            max-width=""
            >
                <v-card-text class="my-4 text-center text-h6" :style="'background-image:('+require( '@/assets/chart.png' )+');'">
                    <v-spacer/>
                    <v-btn
                        class="ma-2"
                        outlined
                        large
                        fab
                        color="primary"
                        @click="$modal.show('metricModal')"
                        >
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </v-card-text>
           </v-card>
          </v-hover>
          <single-metric
            v-else
            :title="metrics.metric[1].description"
            :metric="metrics.metric[1].indicator"
            :icon="metrics.metric[1].icon"
            :loading="loading"
            :helpId="2"
            :customStyles="'position: absolute; right: 5px; bottom: 5px'"
          />
        </v-col>
        <v-col cols="12" sm="6" lg="4" md="4">
          <v-hover v-if="typeof metrics.metric[2] !== 'object'"
            v-slot="{ hover }"
            disabled
          >
            <v-card
            :elevation="hover ? 12 : 2"

            class="mx-auto"
            height="100"
            max-width=""
            >
                <v-card-text class="my-4 text-center text-h6" :style="'background-image:('+require( '@/assets/chart.png' )+');'">
                    <v-spacer/>
                    <v-btn
                        class="ma-2"
                        outlined
                        large
                        fab
                        color="primary"
                        @click="$modal.show('metricModal')"
                        >
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </v-card-text>
           </v-card>
          </v-hover>
          <single-metric
            v-else
            :title="metrics.metric[2].description"
            :metric="metrics.metric[2].indicator"
            :icon="metrics.metric[2].icon"
            :loading="loading"
            :helpId="2"
            :customStyles="'position: absolute; right: 5px; bottom: 5px'"
          />
        </v-col>

        <v-col cols="12" sm="6" lg="6" md="6">
          <v-hover
            v-if="typeof metrics.metric[3] !== 'object'"
            v-slot="{ hover }"
            disabled
          >
            <v-card
            :elevation="hover ? 12 : 2"
            class="mx-auto"
            height="350"
            max-width=""
            >
            <v-img
                :src="require( '@/assets/chart.png' )"
                height=""
                max-width="350"
                style="margin-top:10px;"
              >
                <v-card-text class="my-4 text-center text-h6">
                    <v-spacer/>
                    <v-btn
                        class="ma-2"
                        outlined
                        large
                        fab
                        @click="$modal.show('metricModal')"
                        color="primary"
                        >
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </v-card-text>
            </v-img>
           </v-card>
          </v-hover>
          <v-card v-else class="justify-center single-metric" :loading="loading">
            <v-toolbar flat color="grey lighten-4" style="border: 1px solid #000; height: 35px">
              <v-toolbar-title>
                <h5>{{ metrics.metric[3].description }}</h5>
              </v-toolbar-title>
              <v-spacer />
              <v-btn icon>
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text class="py-0">
              <line-chart
                v-if="metrics.metric[3].type == 2"
                :data="data1.data"
                :options="data1.options"
                :style="{ height: '402px' }"
              />
              <bar-chart
                v-else-if="metrics.metric[3].type == 3"
                :data="data1.data"
                :options="data1.options"
                :style="{ height: '402px' }"
              />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" lg="6" md="6">
          <v-hover
            v-if="typeof metrics.metric[4] !== 'object'"
            v-slot="{ hover }"
            :style="'background-image:('+require( '@/assets/chart.png' )+');'"
            disabled
          >
            <v-card
            :elevation="hover ? 12 : 2"
            class="mx-auto text-center"
            height="350"
            >
                <v-img
                    :src="require( '@/assets/chart.png' )"
                    height=""
                    max-width="350"
                    style="margin-top:10px; margin 0 auto;"
                >
                    <v-card-text class="my-4 text-center text-h6">
                        <v-spacer/>
                        <v-btn
                            class="ma-2"
                            outlined
                            large
                            fab
                            color="primary"
                            @click="$modal.show('metricModal')"
                            >
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                    </v-card-text>
                </v-img>
           </v-card>
          </v-hover>
          <v-card v-else class="justify-center single-metric" :loading="loading">
            <v-toolbar flat color="grey lighten-4" style="border: 1px solid #000; height: 35px">
              <v-toolbar-title>
                <h5>{{ metrics.metric[4].description }}</h5>
              </v-toolbar-title>
              <v-spacer />
              <v-btn icon>
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text class="py-0">
              <line-chart
                v-if="metrics.metric[4].type == 2"
                :data="data2.data"
                :options="data2.options"
                :style="{ height: '402px' }"
              />
              <bar-chart
                v-else-if="metrics.metric[4].type == 3"
                :data="data2.data"
                :options="data2.options"
                :style="{ height: '402px' }"
              />
              <v-progress-circular
                v-else-if="metrics.metric[4].type == 4"
                :value="metrics.metric[4].indicator"
                size="300"
                width="25"
                color="primary"
                style="margin:50px;"
                class="text-center"
              >
                <div class="text-center">
                  <div class="text-h4 ml-3">{{ metrics.metric[4].indicator }}<sup>&#37;</sup></div>
                </div>
              </v-progress-circular>
            </v-card-text>
          </v-card>
        </v-col>
      <modal />
      </v-row>
</template>
<script>
import SingleMetric from '@/components/Stats/SingleMetric'
import LineChart from '@/components/ChartJS/LineChart'
import BarChart from '@/components/ChartJS/BarChart'
import Modal from './Modal'
export default {
    components: {
      SingleMetric,
      LineChart,
      BarChart,
      Modal
    },
    props: {
        metrics: {
            type: Object,
            default: null,
        },
    },
    data: () => ({
      loading: false,
      option: {
        responsive: true,
        scaleOverride: true,
        tooltips: {
          mode: 'index',
          intersect: false,
        },
        hover: {
          mode: 'index',
          intersect: false,
        },
        maintainAspectRatio: false,
        legend: {
          display: false,
          intersect: false,
        },
        layout: {
          padding: {
            left: 10,
            right: 10,
            bottom: 10,
            top: 10,
          },
        },
        scales: {
          xAxes: [
            {
              ticks: {
                display: true,
              },
              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
              gridLines: {
                borderDash: [ 8, 4 ],
                display: true,
                offsetGridLines: true,
              },
            },
          ],
        },
      },

    }),
    computed: {
      data1() {
        return {
          data: {
                  labels: this.metrics.metric[3].data.label,
                  datasets: [
                    {
                      label: this.metrics.metric[3].description,
                      fill: true,
                      data: this.metrics.metric[3].data.value,
                      shadowOffsetX: 3,
                      shadowOffsetY: 3,
                      shadowBlur: 10,
                      shadowColor: 'rgba(103, 116, 132, 0.8)',
                      pointRadius: 0,
                      borderWidth: 0,
                      pointHoverInnerGlowColor: 'rgba(103, 116, 132, 0.8)',
                      order: 0,
                    },
                    {
                      label: 'holis',
                      fill: true,
                      data: this.metrics.metric[3].data.comparative,
                      shadowOffsetX: 3,
                      shadowOffsetY: 3,
                      shadowBlur: 10,
                      shadowColor: 'rgba(230, 23, 23, 0.8)',
                      pointRadius: 0,
                      borderWidth: 0,
                      pointHoverInnerGlowColor: 'rgba(103, 116, 132, 0.8)',
                      order: 0,
                    }
                  ]
          },
          options: this.option,
        };
      },
      data2() {
        return {
          data: {
                  labels: this.metrics.metric[4].data.label,
                  datasets: [
                    {
                      label: this.metrics.metric[4].description,
                      fill: true,
                      data: this.metrics.metric[4].data.value,
                      shadowOffsetX: 3,
                      shadowOffsetY: 3,
                      shadowBlur: 10,
                      shadowColor: 'rgba(103, 116, 132, 0.5)',
                      pointRadius: 0,
                      borderWidth: 0,
                      pointHoverInnerGlowColor: 'rgba(103, 116, 132, 0.5)',
                      order: 0,
                    }
                  ]
          },
          options: this.option,
        };
      },
    },
  }
</script>
