<template>
    <v-row align="center">
        <v-col cols="12" sm="6" lg="3" md="4">
          <v-hover v-if="typeof metrics.metric[0] !== 'object'"
            v-slot="{ hover }"
            disabled
          >
            <v-card
            :elevation="hover ? 12 : 2"

            class="mx-auto"
            height="100"
            max-width=""
            >
                <v-card-text class="my-4 text-center text-h6" :style="'background-image:('+require( '@/assets/chart.png' )+');'">
                    <v-spacer/>
                    <v-btn
                        class="ma-2"
                        outlined
                        large
                        fab
                        color="primary"
                        @click="$modal.show('metricModal')"
                        >
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </v-card-text>
           </v-card>
          </v-hover>
          <single-metric
            v-else
            :title="metrics.metric[0].description"
            :metric="metrics.metric[0].indicator"
            :icon="metrics.metric[0].icon"
            :loading="loading"
            :helpId="2"
            :customStyles="'position: absolute; right: 5px; bottom: 5px'"
          />
        </v-col>
        <v-col cols="12" sm="6" lg="3" md="4">
          <v-hover v-if="typeof metrics.metric[1] !== 'object'"
            v-slot="{ hover }"
            disabled
          >
            <v-card
            :elevation="hover ? 12 : 2"

            class="mx-auto"
            height="100"
            max-width=""
            >
                <v-card-text class="my-4 text-center text-h6" :style="'background-image:('+require( '@/assets/chart.png' )+');'">
                    <v-spacer/>
                    <v-btn
                        class="ma-2"
                        outlined
                        large
                        fab
                        color="primary"
                        @click="$modal.show('metricModal')"
                        >
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </v-card-text>
           </v-card>
          </v-hover>
          <single-metric
            v-else
            :title="metrics.metric[1].description"
            :metric="metrics.metric[1].indicator"
            :icon="metrics.metric[1].icon"
            :loading="loading"
            :helpId="2"
            :customStyles="'position: absolute; right: 5px; bottom: 5px'"
          />
        </v-col>
        <v-col cols="12" sm="6" lg="3" md="4">
          <v-hover  v-if="typeof metrics.metric[2] !== 'object'"
            v-slot="{ hover }"
            disabled
          >
            <v-card
            :elevation="hover ? 12 : 2"

            class="mx-auto"
            height="100"
            max-width=""
            >
                <v-card-text class="my-4 text-center text-h6" :style="'background-image:('+require( '@/assets/chart.png' )+');'">
                    <v-spacer/>
                    <v-btn
                        class="ma-2"
                        outlined
                        large
                        fab
                        color="primary"
                        @click="$modal.show('metricModal')"
                        >
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </v-card-text>
           </v-card>
          </v-hover>
          <single-metric
            v-else
            :title="metrics.metric[2].description"
            :metric="metrics.metric[2].indicator"
            :icon="metrics.metric[2].icon"
            :loading="loading"
            :helpId="2"
            :customStyles="'position: absolute; right: 5px; bottom: 5px'"
          />
        </v-col>
        <v-col cols="12" sm="6" lg="3" md="4">
          <v-hover  v-if="typeof metrics.metric[3] !== 'object'"
            v-slot="{ hover }"
            disabled
          >
            <v-card
            :elevation="hover ? 12 : 2"

            class="mx-auto"
            height="100"
            max-width=""
            >
                <v-card-text class="my-4 text-center text-h6" :style="'background-image:('+require( '@/assets/chart.png' )+');'">
                    <v-spacer/>
                    <v-btn
                        class="ma-2"
                        outlined
                        large
                        fab
                        color="primary"
                        @click="$modal.show('metricModal')"
                        >
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </v-card-text>
           </v-card>
          </v-hover>
          <single-metric
            v-else
            :title="metrics.metric[3].description"
            :metric="metrics.metric[3].indicator"
            :icon="metrics.metric[3].icon"
            :loading="loading"
            :helpId="2"
            :customStyles="'position: absolute; right: 5px; bottom: 5px'"
          />
        </v-col>

        <v-col cols="12" sm="6" lg="6" md="6">
          <v-hover
            v-slot="{ hover }"
            disabled
          >
            <v-card
            :elevation="hover ? 12 : 2"
            class="mx-auto"
            height="350"
            max-width=""
            >
            <v-img
                :src="require( '@/assets/chart.png' )"
                height=""
                max-width="350"
                style="margin-top:10px;"
              >
                <v-card-text class="my-4 text-center text-h6">
                    <v-spacer/>
                    <v-btn
                        class="ma-2"
                        outlined
                        large
                        fab
                        color="primary"
                        @click="$modal.show('metricModal')"
                        >
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </v-card-text>
            </v-img>
           </v-card>
          </v-hover>
        </v-col>
        <v-col cols="12" sm="6" lg="6" md="6">
          <v-hover
            v-slot="{ hover }"
            disabled
          >
            <v-card
            :elevation="hover ? 12 : 2"
            class="mx-auto"
            height="350"
            max-width=""
            >
            <v-img
                :src="require( '@/assets/chart.png' )"
                height=""
                style="margin-top:10px;"
                max-width="350"
              >
                <v-card-text class="my-4 text-center text-h6">
                    <v-spacer/>
                    <v-btn
                        class="ma-2"
                        outlined
                        large
                        fab
                        color="primary"
                        @click="$modal.show('metricModal')"
                        >
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </v-card-text>
            </v-img>
           </v-card>
          </v-hover>
          <modal />
        </v-col>
      </v-row>
</template>
<script>
import SingleMetric from '@/components/Stats/SingleMetric'
import Modal from './Modal'
export default {
    components: {
      SingleMetric,
      Modal
    },
    props: {
        metrics: {
            type: Object,
            default: null,
        },
    },
    data: () => ({
      stats: {},
      loading: false,
      menuItems: [
        {
          id: 1,
          name: 'Cursos y Lecciones',
          icon: 'mdi-clipboard-edit-outline',
          link: '/business/elearning/courses',
        }
      ],
    }),
  }
</script>
